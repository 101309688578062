/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/purple-green.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.mat-raised-button.mat-primary,
.mat-raised-button.mat-accent {
    background-color: #029c4f;
    color: white;
}

.mat-dialog-container {
    background-color: #F7F7E6;
    color: black;
}

.cdk-overlay-dark-backdrop {
 background: rgba(107, 107, 107, 1);
}

.custom-container .mat-dialog-container {
  background: #F7F7E6;
  mix-blend-mode: normal;
  border: 2px solid #029C4F;
  box-sizing: border-box;
  border-radius: 20px;
}

.custom-container {
  overflow-y: auto;
}
